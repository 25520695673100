<template>
  <v-card height="100%">
    <iframe
      id="rc-chat"
      :v-if="chatUrl"
      :src="chatUrl"
      title="Sentry Health Chat"
      width="100%"
      height="100%"
    ></iframe>
    <div class="chat-errors__wrap d-flex justify-center align-center" v-if="loading || errors">
      <div v-if="errors">There was an error loading chat. Please try again.</div>
      <v-progress-circular indeterminate size="50" color="white" v-else />
    </div>
  </v-card>
</template>
<style scoped>
.chat-errors__wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  color: white;
}
</style>
<script>
import GET_ROCKET_CHAT_TOKEN from '../graphql/Query/GetChatToken.gql';
import GET_CHAT_SETTINGS from '../graphql/Query/GetChatSettings.gql';

export default {
  name: 'Chat',
  components: {},
  data() {
    return {
      token: undefined,
      chatUrl: null,
      errors: false,
      loading: false,
    };
  },

  methods: {
    async getChatUrl() {
      const { providerId } = this.$store.getters['patientPortal/currentUser'];

      const query = await this.$apollo.query({
        query: GET_CHAT_SETTINGS,
        variables: {
          providerId,
        },
        fetchPolicy: 'no-cache',
      });
      const { getChatSettings } = query.data;
      const { chatUrl } = getChatSettings;
      this.chatUrl = chatUrl;
    },
    async getRocketChatToken() {
      const query = await this.$apollo.query({ query: GET_ROCKET_CHAT_TOKEN });
      const { getChatToken: token } = query.data;
      this.token = token;
    },
  },
  async mounted() {
    this.loading = true;
    try {
      await this.getChatUrl();
      // get rocket chat token
      await this.getRocketChatToken();

      const iframe = document.getElementById('rc-chat');
      iframe.contentWindow.onerror = () => {
        throw new Error();
      };

      setTimeout(() => {
        iframe.contentWindow.postMessage(
          {
            externalCommand: 'login-with-token',
            token: this.token,
          },
          '*'
        );
      }, 3000);
    } catch {
      this.errors = true;
    }
    this.loading = false;
  },
};
</script>
